<template>
  <b-card>
    <div>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        관리자 공지사항
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          완료
        </b-badge>
      </h4>
      <hr>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        v-model="visibleRows"
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :items="fetchSetNoticeAdmin"
        :fields="fields1"
        style="text-align: center; font-size: small;"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template
          #cell(no)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ data.index + 1 }}
        </template>
        <template #cell(category)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.item.category }}
          </div>
        </template>
        <template #cell(title)="data">
          <div
            size="sm"
            style="text-align: left; min-width: 15rem"
          >
            {{ data.item.title }}
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 7rem"
          >
            {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
        <template #cell(updatedAt)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 7rem"
          >
            {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
        <template
          #cell(edit)="data"
          v-slot:button-content
        >
          <div
            class="editClass"
            style="min-width: 3rem"
            @click.stop
          >
            <feather-icon
              v-b-modal.modal-lg
              icon="Edit2Icon"
              class="mr-1"
              style="color:rgb(229,166,48);"
              type="button"
              @click="rowClick(data)"
            />
            <feather-icon
              icon="TrashIcon"
              class=""
              style="color:rgb(229,166,48);"
              @click="submit('deleteSetNoticeAdmin', data)"
            />
          </div>
        </template>
      </b-table>
      <b-modal
        id="modal-lg"
        ref="my-modal"
        small
        title="상세설정"
        centered
        button-size="sm"
        size="lg"
        style="max-width: 400px !important;"
        ok-title="저장"
        @ok="submit('upsertSetNoticeAdmin')"
      >
        <b-col cols="12">
          <div
            class="text-info"
          >
            {{ detailTitle }}
          </div>
          <hr>
          <b-form-group
            class="font-small-3 text-info"
            label="카테고리"
            label-for="event-period"
            label-cols-md="1"
          >
            <b-form-select
              id="signup-referral"
              v-model="detailCategory"
              :options="options1"
              class="category-select font-small-3"
              size="sm"
              style="color:rgb(229,166,48)"
              :disabled="false"
            />
            <!--            </b-form-select>-->
          </b-form-group>
        </b-col>
        <div class="mt-2">
          <quill-editor
            ref="myTextEditor"
            v-model="detailContent"
            class="editor"
            :disabled="false"
            :options="editorOption"
          />
        </div>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <hr>
      <b-button
        ref="submit"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="content-right w-auto mr-1 mt-1"
        size="sm"
        :to="{ name: 'notice-admin-detail', params: { type: 'notice', id: fetchSetNoticeAdmin.length + 1 } }"
      >
        공지사항 생성
      </b-button>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { FETCH_SET_NOTICE_ADMIN } from '@/store/notice/action'
import { DELETE_SET_NOTICE_ADMIN, UPSERT_SET_NOTICE_ADMIN } from '@/store/notice/mutation'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      recommendNickname: '',
      perPage: 20,
      visibleRows: [],
      pageOptions: [20, 30, 50],
      currentPage: 1,
      totalRows: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      detailCategory: '',
      detailTitle: '',
      detailDataRow: 0,
      detailContent: '',
      fields1: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col3',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'edit', sortable: false, label: '상세', thClass: 'col7',
        },
      ],
      tableItems1: [
        {
          no: 1,
          category: '안내',
          title: '첫번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
        {
          no: 2,
          category: '점검',
          title: '두번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
        {
          no: 3,
          category: '점검',
          title: '세번째 공지입니다 공지사항 테스트 입력',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
        },
      ],
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ font: [] }], // 글꼴 class로 제어
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            // ['blockquote', 'code-block'], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ align: [] }], // class
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }], // <sub>, <sup>
            // [{ indent: '-1' }, { indent: '+1' }], // class제어
            // [{ direction: 'rtl' }], // class 제어
            // [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            // ['clean'],
            ['link', 'image', 'video'],
          ],
        },
      },
      editorOption2: {
        modules: {
          toolbar: [
            [''],
          ],
        },
      },
      content: `<h2><span class="ql-font-serif">Quill Rich Text Editor</span></h2><br/>
                <p>Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built for the modern web. With its <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.</p><br />
                <iframe class="ql-video" src="https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0" width="560" height="238"></iframe>`,
      options1: [
        { value: '안내', text: '안내' },
        { value: '점검', text: '점검' },
        { value: '긴급', text: '긴급' },
        { value: '기타', text: '기타' },
      ],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchSetNoticeAdmin: 'fetchSetNoticeAdmin',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchSetNoticeAdmin
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $fetchSetNoticeAdmin: FETCH_SET_NOTICE_ADMIN,
      $upsertSetNoticeAdmin: UPSERT_SET_NOTICE_ADMIN,
      $deleteSetNoticeAdmin: DELETE_SET_NOTICE_ADMIN,
    }),
    async fetchData() {
      await this.$fetchSetNoticeAdmin({
        site: this.authSite,
      })
      this.totalRows = this.fetchSetNoticeAdmin.length
    },
    rowClick(data) {
      this.detailDataRow = data.item.no
      this.detailCategory = data.item.category
      this.detailTitle = data.item.title
      this.detailContent = data.item.content
    },
    async submit(target, row) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, row)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      const mySite = this.$site
      const adminId = this.userData.userid
      try {
        if (target === 'upsertSetNoticeAdmin') {
          await this.$upsertSetNoticeAdmin({
            site: mySite,
            category: this.detailCategory,
            title: this.detailTitle,
            content: this.detailContent,
            enrollId: adminId,
          })
        }
        if (target === 'deleteSetNoticeAdmin') {
          await this.$deleteSetNoticeAdmin({
            site: mySite,
            title: data.item.title,
            category: data.item.category,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    // delete row
    deleteRow(type, row) {
      Swal.fire({
        title: '선택한 항목을 삭제하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(type, row)
        }
      })
    },
    rowClickHandler(record, index, type) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(record) // This will be the item data for the row
      // console.log(index)
      // console.log(type.srcElement.parentElement.ariaColIndex)
      // if (type.target.ariaColIndex !== '6' && type.srcElement.parentElement.ariaColIndex !== '6' && type.srcElement._prevClass !== 'feather feather-trash') {
      if (type.target.ariaColIndex !== '6') {
        this.detailDataRow = record.no
        this.detailCategory = record.category
        this.detailTitle = record.title
        this.detailContent = record.content
        this.$refs['my-modal'].show()
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
</style>
